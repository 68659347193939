<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'downloadView',

  mounted() {
    this.redirectByDevice();
  },

  methods: {
    redirectByDevice() {
      const androidLink = "https://play.google.com/store/apps/details?id=com.entlq.aqarcom";
      const iosLink = "https://apps.apple.com/sa/app/%D8%B9%D9%82%D8%A7%D8%B1-%D9%83%D9%88%D9%85-aqarcom/id1568547797";
      const huaweiLink = "https://appgallery.huawei.com/app/C107892651";
      const desktopLink = "https://aqarcom.sa";

      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        window.location.replace(androidLink);
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.replace(iosLink);
      } else if (/huawei/i.test(userAgent) || /HarmonyOS/i.test(userAgent)) {
        window.location.replace(huaweiLink);
      } else {
        window.location.replace(desktopLink);
      }
    },
  },
});
</script>
