<template>
      <div style="margin-top: 20px" class="col-md-12 col-sm-12 col-xs-12">
        <div class="advantages" style="overflow-x:auto;">
          <h4>{{$t('ad_f')}}</h4>

          <div class="row table-ad">



          <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.adLicenseNumber">
            <div class="yes-image table-td-ad" >
               <v-icon icon="mdi-license" class="mx-1"></v-icon>
            </div>
            <div class="table-td-ad ad-td-title" style="background-color: #F6F6F6;">

              {{$t('Advertising_license_number')}}
               </div>

            <div class="table-td-ad ad-td-value " ><span> {{platform.adLicenseNumber}} </span></div>
          </div>


          <div class="col-md-6 col-sm-12 col-xs-12" v-if="platform.propertyArea">
            <div class="yes-image table-td-ad" >
              <v-icon icon="mdi-texture-box" class="mx-1"></v-icon>
            </div>
            <div  class="table-td-ad ad-td-title"  style="background-color: #F6F6F6;">{{$t("propertyArea")}}</div>

            <div  class="table-td-ad ad-td-value "  ><span> {{ platform.propertyArea.toFixed(2) }} {{$t("m_2")}} </span></div>

          </div>



            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.propertyAge">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div class="table-td-ad ad-td-title" style="background-color: #F6F6F6;"> {{$t('age_aqar')}} </div>
              <div class="table-td-ad ad-td-value " ><span> {{platform.propertyAge}} </span></div>
            </div>


            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.planNumber">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div  class="table-td-ad ad-td-title"  style="background-color: #F6F6F6;">{{$t('planNumber')}}</div>

              <div  class="table-td-ad ad-td-value "  ><span> {{platform.planNumber}} </span></div>

            </div>


            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.landNumber">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div class="table-td-ad ad-td-title" style="background-color: #F6F6F6;"> {{$t('landNumber')}} </div>
              <div class="table-td-ad ad-td-value " ><span> {{platform.landNumber}} </span></div>
            </div>


            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.propertyFace">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div  class="table-td-ad ad-td-title"  style="background-color: #F6F6F6;">{{$t('planNumber')}}</div>

              <div  class="table-td-ad ad-td-value "  ><span> {{platform.propertyFace}} </span></div>

            </div>

            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.propertyUtilities">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div class="table-td-ad ad-td-title" style="background-color: #F6F6F6;"> {{$t('propertyUtilities')}} </div>
              <div class="table-td-ad ad-td-value " >
                    <span
                        class="propertyUtilities-value"
                        v-for="(item, i) in platform.propertyUtilities"
                        :key="i"
                        v-if="platform.propertyUtilities && platform.propertyUtilities.length"
                    >
                      <p v-if="i>0">-</p>  {{ item }}
                    </span>

              </div>
            </div>


            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.planNumber">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div  class="table-td-ad ad-td-title"  style="background-color: #F6F6F6;">{{$t('planNumber')}}</div>

              <div  class="table-td-ad ad-td-value "  ><span> {{platform.planNumber}} </span></div>

            </div>


            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.numberOfRooms">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div class="table-td-ad ad-td-title" style="background-color: #F6F6F6;"> {{$t('numberOfRooms')}} </div>
              <div class="table-td-ad ad-td-value " >
                    <span >
                      {{ platform.numberOfRooms }} {{$t('room')}}
                    </span>

              </div>
            </div>


            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.streetWidth">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div  class="table-td-ad ad-td-title"  style="background-color: #F6F6F6;">{{$t('streetWidth')}}</div>

              <div  class="table-td-ad ad-td-value "  ><span> {{platform.streetWidth}} {{$t('m_2')}} </span></div>

            </div>

            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.guaranteesAndTheirDuration">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div class="table-td-ad ad-td-title" style="background-color: #F6F6F6;"> {{$t('guaranteesAndTheirDuration')}} </div>
              <div class="table-td-ad ad-td-value " >
                    <span>
                       {{ platform.guaranteesAndTheirDuration }}
                    </span>

              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12"  v-else-if="platform.guaranteesAndTheirDuration === ''">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div class="table-td-ad ad-td-title" style="background-color: #F6F6F6;"> {{$t('guaranteesAndTheirDuration')}} </div>
              <div class="table-td-ad ad-td-value " >
                    <span>
                       {{ $t('not_found')}}
                    </span>

              </div>
            </div>

            <div class="col-md-6 col-sm-12 col-xs-12"  v-if="platform.obligationsOnTheProperty">
              <div class="yes-image table-td-ad" >
                <v-icon icon="mdi-checkbox-multiple-marked" class="mx-1"></v-icon>
              </div>
              <div  class="table-td-ad ad-td-title"  style="background-color: #F6F6F6;">{{$t('obligationsOnTheProperty')}}</div>

              <div  class="table-td-ad ad-td-value "  ><span> {{platform.obligationsOnTheProperty}} </span></div>

            </div>
          </div>
        </div>
      </div>
</template>
<script >
export default {
  props: {
    platform: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.advantages {
  border: 1px solid #E6E8F0;
  border-radius: 12px;
  margin-bottom: 25px;
}

.advantages h4 {
  padding-right: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #141835;
  margin-top: 20px;
  margin-bottom: 20px;
}

.table-ad  {
  border-spacing: 0;
  overflow: hidden;
  inline-size: 100%;
  text-align: right;
  background-color: inherit;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
   margin-top: 10px;
}
.table-ad  .table-td-ad {
  padding: 14px;
  min-inline-size: auto;
  border-block-end: 1px solid lightgray;
  font-size: 12px;
  line-height: 25px;
  font-weight: bolder;
  color: #323235;
}
.table-td-ad.ad-td-title {
  font-size: 15px;
}
.table-ad  .col-md-6.col-sm-12.col-xs-12{
  display: inline-flex;
}
.table-ad .table-td-ad img {
  float: left;
  width: 25px;
}
.table-ad .table-td-ad {
  text-align: center;
  font-weight: bolder;
  border-inline-start: 1px solid lightgray;
}

.table-ad  .ad-td-value {
  text-align: end !important;
}
.table-ad .table-td-ad span {
  display: block;
  font-size: 14px;
  color: #0d0f3c;
  font-weight: bolder;
}
span.propertyUtilities-value {
  display: inline-flex !important
;
  margin: 0;
}
span.propertyUtilities-value p {
  margin: 0 5px;
}
.mdi-checkbox-multiple-marked  ,.mdi-license , .mdi-texture-box{
  color: rgb(199 163 22);
  font-size: 24px;
}
.mdi-license ,.mdi-texture-box{
  font-size: 26px;
 }

.yes-image.table-td-ad {
  width: 10% !important;
}
.table-td-ad.ad-td-title {
  width: 30% !important;
}
.table-ad .ad-td-value{
  width: 60% !important;
}

@media (max-width: 991.98px) {
  .yes-image.table-td-ad {
    width: 20% !important;
  }
  .table-td-ad.ad-td-title {
    width: 40% !important;
  }
  .table-ad .ad-td-value{
    width: 40% !important;
  }

  .table-ad .table-td-ad {
    height: 100%;
    padding: 6px;
    font-size: 13px;
    margin: auto;
    text-align: center !important;
  }
}
</style>