<template>
    <v-container class="v-container-spe">
      <Blog />
    </v-container>
  </template>
  
  <script>
  import Blog from "../components/Blog/Blog.vue";
  
  export default {
    components: {
        Blog,
    },
  };
  </script>
  <style>
    @media (min-width: 1280px) {
      .v-container.v-container-spe {
        max-width: 1400px !important;
      }
    }
  </style>