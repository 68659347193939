<template>
  <v-app>
    <v-main>
      <v-container class="navbar-cont" v-if="showNavFooter">
      <Navbar />
    </v-container>
    <v-divider class="v-divider-h"></v-divider>
    <v-container  class="Navitem-cont"  v-if="showNavFooter">
      <Navitem />
    </v-container>
    <v-divider  class="v-divider-h"></v-divider>
    <router-view />
    <Footer v-if="showNavFooter"/>
    <WhatsApp/>
    </v-main>
  </v-app>
</template>

<script>


import WhatsApp from './components/WhatsApp/WhatsApp.vue';
import Navbar from './components/Navbar/Navbar.vue';
import Navitem from './components/Navitem/Navitem.vue';
import Footer from './components/Footer/Footer.vue';
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
export default {
  name: "App",
  data: () => ({}),
  components: {
    simplebar,
    Navbar,
    Navitem,
    Footer,
    WhatsApp
  },
  computed: {
    showNavFooter() {
      // Replace 'NotFound' with the name of your 404 route
      return this.$route.name !== 'NotFound';
    }
  }
};
</script>
<style>

@media (max-width: 991.98px) {
  .v-divider-h{
    display: none;
  }
  .Navitem-cont {
    padding: 0 15px !important;
  }
}

</style>
