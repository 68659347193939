<template>
    <div class="d-flex align-center w-100 justify-space-between navitem">
        <v-list class="d-flex align-center justify-end w-100">
        <v-list-item
    v-for="(item, index) in items"
    :key="index"
    @click="navigateTo(item.route)"
  >
    <v-list-item-title class="font-weight-bold" style="font-size: 1.1rem;">{{$t(item.text)}}</v-list-item-title>

  </v-list-item>
    </v-list>
  <Authriz class="w-25 text-end"/>

    </div>


</template>

<script>
import Authriz from './Authriz'
export default {
    components:{
        Authriz
    },
  data() {
    return {
      drawer: false,
      items: [
        { text: "Home", route: "/" },
        { text: "Real_estate", route: "/real-estate" },
        // { text: "Support-Service", route: "/support-service" },
        { text: "Blog", route: "/blog" },
        { text: "About", route: "/about-us" },
        { text: "Contact", route: "/contact" },


      ],
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route)
    },
  },
};
</script>


<style lang="scss" scoped>

@media (max-width: 1270px) {
  .v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
}
}
@media (max-width: 991.98px) {

.navitem{
display: none !important;
}
}
</style>