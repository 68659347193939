<template>
  <section v-if="items.length>0">
    <div class="text-center my-6">
      <p class="text-h4 font-weight-bold">
        {{ $t("Blog") }}
      </p>
      <p style="color: #00000087; font-size: 20px" class="font-weight-bold">
        {{ $t("Through_section") }}
      </p>
    </div>
    <v-row>
      <v-col v-for="(item, i) in items" :key="i" :md="6" :cols="12" class="py-0"  style="
    margin: 10px 0;
">
        <v-card height="100%"  class="custom-card" @click="singleblog(item.id)">
          <v-row align="center" >
            <v-col cols="4" md="4">
              <v-img
            :src="item.image"
            height="125px"
            class="rounded imgblog ma-3"
        
          ></v-img>
            </v-col>
            <v-col cols="8" md="8">
        
              <h3 class="font-weight-bold">
              {{ item.title }}
            </h3>
              <v-icon
                  class="share-icon"
                  @click.stop="shareItem(item)"
                  color="blue"
                  style="cursor: pointer;"
              >
                mdi-share-variant
              </v-icon>
            <div class="wrapper">
              <p class="item-description-p" style="
    font-size: 14px;
">
                {{ item.description }}
              </p>
            </div>
          
        
            </v-col>
     
          </v-row>         
        </v-card>
      </v-col>
    </v-row>
  </section>
  <v-row v-else >
  
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>

</v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
export default {
  data() {
    return {
      items: [],
      itemsPerRow: 3,
    };
  },

  methods: {
    async blog() {
      let response = await crudDataService.getAll(`blogs`);
      this.items = response.data.data;
      // console.log(this.items);
    },
    shareItem(item) {
      navigator.share({
        title: item.title,
        text: item.description,
        url: `${window.location.origin}/single-blog/${item.id}`,
      })
          .then(() => {
            console.log("تمت المشاركة بنجاح!");
          })
          .catch((error) => {
            console.error("حدث خطأ أثناء المشاركة:", error);
          });

    },
    singleblog(id){
this.$router.push({ name: 'SingleBlog', params:{ id } })

    },
    // calculateCols(index) {
    //   const totalItems = this.items.length;

    //   const itemsInCurrentRow =
    //     index % this.itemsPerRow === 0
    //       ? this.itemsPerRow
    //       : index % this.itemsPerRow;
    //   return (
    //     Math.floor(
    //       12 / (totalItems >= this.itemsPerRow ? this.itemsPerRow : totalItems)
    //     ) * itemsInCurrentRow
    //   );
    // },
  },
  mounted() {
    this.blog();
  },
};
</script>
<style>
.imgblog .v-img__img--contain {
  object-fit: scale-down;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
}
</style>
<style scoped>
.wrapper {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-size: 18px;
  height: 100%;
}
.custom-card {
  cursor: pointer;
  box-shadow: none;
  border-bottom: 1px solid #adacac7e;
  border-radius: 0;
}
i.mdi-share-variant.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default.text-blue.v-icon--clickable.share-icon {
  float: left;
  top: -35px;
  color: #dbaf03 !important;
  font-size: 25px;
}
.font-weight-bold {
  font-size: 16px;
  font-weight: 700 !important;
}

@media (max-width: 700px) {
  .item-description-p {
    font-size: 11px !important;
  }
  i.mdi-share-variant.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default.text-blue.v-icon--clickable.share-icon[data-v-4f07928f] {
    top: -15px;
    font-size: 15px;
  }
  .font-weight-bold[data-v-4f07928f] {
    font-size: 11px;
    font-weight: 600;
  }
  .v-responsive.v-img.rounded.imgblog.ma-3 {
    height: 100% !important;
    margin: 0 !important;
    width: 100% !important;
  }
}

</style>
