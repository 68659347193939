<template>
<v-container >
   <section v-if ="Object.entries(items).length>0 ">
    <div class="text-center my-6" >
      <v-icon
          class="share-icon"
          @click.stop="shareItem(items)"
          style="cursor: pointer;"
      >
        mdi-share-variant
      </v-icon>
      <p class="text-h4 font-weight-bold">
        {{ $t("Blog") }}
      </p>
      <p style="color: #00000087; font-size: 20px" class="font-weight-bold">
        {{ $t("Through_section") }}
      </p>
    </div>

    <div class="d-flex  flex-column">
     
        <img :src="items.image" class="rounded" style="height: 350px;object-fit: contain;" alt="" />
     
        <div class="pa-7">
          <div class="d-flex flex-lg-row flex-column justify-space-between">
            <h3 class="font-weight-bold" style="
    font-size: 20px;
">
              {{ items.title }}

            </h3>
            <p class="text-end">
              <v-icon icon="mdi-clock-time-five-outline"></v-icon>
              {{ items.date }}



            </p>
          </div>
          <div class=""  style="
    font-size: 18px;
"   v-html="formattedText">
          </div>
        </div>
      </div>
   </section>
    
      <v-row v-else >
  
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>
  <v-col cols="12" >
    <v-skeleton-loader
      class="mx-auto border-0 "
      type="image,paragraph"
    ></v-skeleton-loader>
  </v-col>

</v-row>
</v-container>

</template>

<script>
import crudDataService from "@/Services/crudDataService";
export default {
  data() {
    return {
      items: [],
      itemsPerRow: 3,
    };
  },
  computed: {
    formattedText() {
      let formattedText = this.items.description.replace(/##(.*?)##/g, '<span style="color: #1270a9;' +
          '  font-weight: 600;">$1</span>');

      return formattedText.replace(/\n/g, "<br>");
    }
  },
  methods: {
    async singleblog() {
      let response = await crudDataService.get(
        "blogs",
        `${this.$route.params.id}`
      );
      this.items = response.data.data;
      // console.log(this.items);
    },
    shareItem(item) {
      navigator.share({
        title: item.title,
        text: item.description,
        url: `${window.location.origin}/single-blog/${item.id}`,
      })
          .then(() => {
            console.log("تمت المشاركة بنجاح!");
          })
          .catch((error) => {
            console.error("حدث خطأ أثناء المشاركة:", error);
          });

    },
  },
  mounted() {
    this.singleblog();
  },
};
</script>

<style scoped>
i.mdi-share-variant.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default.v-icon--clickable.share-icon {
  float: left;
  font-size: 28px;
  color: #d9a50a;
}
span.highlighted-text {

}
</style>